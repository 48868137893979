<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">人流歷史紀錄查詢</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })">
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" />
                列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })">
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })">
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出
                HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="grid.canCreateRow && !grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <div>
      <div class="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6" style="position:relative">
        <!-- BEGIN: pie -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <div id="pie-chart" class="p-5">
              <div class="preview">
                <PieChart v-bind="pieChart" />
              </div>
            </div>
          </div>
        </div>
        <!-- END: pie -->
        <!-- BEGIN: line -->
        <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
          <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
            <div class="flex items-center">
              <label>選擇地點</label>
              <vxe-select v-model="barChartOptions.selectedId" class="flex-1"
                @change="({ value }) => changeHotspot(value)">
                <vxe-option v-for="item in barChartOptions.selectorOptions" :key="'hotspot-' + item.Id" :value="item.Id"
                  :label="item.Name"></vxe-option>
              </vxe-select>
            </div>
            <div id="line-chart" class="p-5">
              <div class="preview">
                <BarChart v-bind="barChart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <DateRange triggerType="immediate" :start-date="gridQueryParams.startDate" :end-date="gridQueryParams.endDate"
        date-type="c" @query="e => { gridQueryParams.startDate = e.startDate; gridQueryParams.endDate = e.endDate; }" />
      <div class="flex items-center mb-2">
        <vxe-select v-model="gridQueryParams.hotspotId" placeholder="請選擇地點">
          <vxe-option v-for="item in hotspots" :key="'hotspot-' + item.Id" :value="item.Id" :label="item.Name">
          </vxe-option>
        </vxe-select>
      </div>
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <vxe-button @click="grid.onExport({ type: 'csv' })" :loading="isExporting" icon="vxe-icon--download"
            content="匯出 CSV">
          </vxe-button>
          <!-- <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })">
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit" @removeSelectedRows="onGridRemoveSelectedRows">
          <template #modal="{ row }">
            <div v-if="row.HolidayString">
              {{ row.Hotspot?.Name }} / 時間:
              <span class="text-red-500">{{ formatDate(row.Date, "yyyy/MM/dd") }}({{
                  row.HolidayString
              }})</span>
            </div>
            <div v-else>
              {{ row.Hotspot?.Name }} / 時間:
              {{ formatDate(row.Date, "yyyy/MM/dd") }}
            </div>
            <div class="col-span-12 grid grid-cols-12 gap-6 relative">
              <div class="col-span-12 h-1/2">
                <img :src="row.Hotspot?.Planimetric?.Uri" />
              </div>
              <div class="col-span-12 mt-6">
                <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                  <div id="line-chart" class="p-5">
                    <div class="preview">
                      <LineChart v-bind="previewLineChartData" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <vxe-form class="ml-3" v-bind="formOptions" :data="row.Xml">
              <template #xml="{ data }">
                <vxe-table
                  stripe
                  resizable
                  border
                  round
                  :data="data?.DeltaColumns"
                >
                  <vxe-table-column
                    field="Name"
                    title="欄位"
                  ></vxe-table-column>
                  <vxe-table-column
                    field="Original"
                    title="修改前"
                  ></vxe-table-column>
                  <vxe-table-column
                    field="Current"
                    title="修改後"
                    :resizable="false"
                  ></vxe-table-column>
                </vxe-table>
              </template>
            </vxe-form> -->
          </template>
          <template #column-date="{ row }">
            <span v-if="row.HolidayString" class="text-red-500">{{ formatDate(row.Date, "yyyy/MM/dd") }}({{
                row.HolidayString
            }})</span>
            <span v-else>{{ formatDate(row.Date, "yyyy/MM/dd") }}</span>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, reactive } from "vue";
import CloudFun, {
  Condition,
  Operator,
  Sorting,
  SortOrder
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import PieChart from "@/components/pie-chart/Main.vue";
import BarChart from "@/components/bar-chart/Main.vue";
import formatDate from "xe-utils/toDateString";
import DateRange from "@/components/date-select/Range.vue";
import { Hotspot, PeopleFlow, COLOR_PALETTE } from ".";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  components: {
    Grid,
    PieChart,
    BarChart,
    DateRange
  },
  setup() {
    const model = CloudFun.current?.model;
    const peopleFlows = ref<PeopleFlow[]>([]);
    const hotspots = ref<Hotspot[]>([]);
    const barChartOptions = reactive<{ selectedId: number | null; selectorOptions: Hotspot[] }>({
      selectedId: null,
      selectorOptions: []
    })
    const isExporting = ref(false);

    const gridQueryParams = reactive<{
      hotspotId: number | null;
      startDate: Date;
      endDate: Date;
    }>({
      hotspotId: null,
      startDate: new Date(),
      endDate: new Date()
    });
    // gridQueryParams.startDate.setMonth(gridQueryParams.startDate.getMonth() - 1);
    gridQueryParams.startDate.setHours(0, 0, 0, 0);
    gridQueryParams.endDate.setHours(23, 59, 59, 0);

    const pieChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [] as string[],
        datasets: [
          {
            data: [] as number[],
            backgroundColor: COLOR_PALETTE,
            // hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
          }
        ]
      }
    });

    const chartBackgroundColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(75, 192, 192, 0.2)"
    ];

    const borderColors = ["rgb(255, 99, 132)", "rgb(75, 192, 192)"];

    const barChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ],
        datasets: [
          {
            label: "人數",
            data: [] as any[],
            borderWidth: 2,
            borderColor: [] as string[],
            backgroundColor: [] as string[],
            pointBorderColor: "transparent"
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Min and Max Settings"
          },
          legend: {
            title: {
              display: false,
              position: "bottom"
            }
          }
        }
      }
    });

    const previewLineChartData = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ],
        datasets: [
          {
            label: "人數",
            data: [] as any[],
            borderWidth: 2,
            borderColor: "#3160D8",
            backgroundColor: "transparent",
            pointBorderColor: "transparent"
          }
        ]
      }
    });

    const changeHotspot = async (id: number | null) => {
      barChartOptions.selectedId = id;
      barChart.data.labels = [];
      barChart.data.datasets[0].data = [];
      if (!barChartOptions.selectedId) return;
      const condition = new Condition();
      condition.and("HotspotId", Operator.Equal, id);
      if (gridQueryParams.startDate)
        condition.and(
          "Date",
          Operator.GreaterThanOrEqual,
          gridQueryParams.startDate
        );
      if (gridQueryParams.endDate)
        condition.and("Date", Operator.LessThan, gridQueryParams.endDate);


      const barChartRange = {
        start: new Date(gridQueryParams.startDate.getTime()),
        end: new Date(gridQueryParams.endDate.getTime())
      };

      while (barChartRange.start.getTime() <= barChartRange.end.getTime()) {
        const flow = peopleFlows.value.find(
          e =>
            e.HotspotId === barChartOptions.selectedId &&
            new Date(e.Date).getTime() === barChartRange.start.getTime()
        );
        if (flow) {
          console.log(
            "🚀 ~ file: Main.vue ~ line 428 ~ changeHotspot ~ flow",
            flow
          );

          barChart.data.datasets[0].data.push(flow.CumePersons);
          barChart.data.labels.push(formatDate(barChartRange.start, "MM/dd"));

          const colorIndex = flow.HolidayString ? 0 : 1;
          console.log(
            "🚀 ~ file: Main.vue ~ line 440 ~ changeHotspot ~ colorIndex",
            colorIndex
          );

          barChart.data.datasets[0].backgroundColor.push(
            chartBackgroundColors[colorIndex]
          );

          barChart.data.datasets[0].borderColor.push(borderColors[colorIndex]);
        } else {
          barChart.data.datasets[0].data.push(0);
          barChart.data.datasets[0].backgroundColor.push(
            chartBackgroundColors[1]
          );
          barChart.data.datasets[0].borderColor.push(borderColors[1]);
          barChart.data.labels.push(formatDate(barChartRange.start, "MM/dd"));
        }
        barChartRange.start.setDate(barChartRange.start.getDate() + 1);
      }
    };

    const getStatistics = async () => {
      try {
        const condition = new Condition();
        if (gridQueryParams.hotspotId)
          condition.and(
            "HotspotId",
            Operator.Equal,
            gridQueryParams.hotspotId
          );
        if (gridQueryParams.startDate)
          condition.and(
            "Date",
            Operator.GreaterThanOrEqual,
            gridQueryParams.startDate
          );
        if (gridQueryParams.endDate)
          condition.and("Date", Operator.LessThan, gridQueryParams.endDate);

        peopleFlows.value = (
          await model?.dispatch("peopleFlow/statistics", {
            condition,
            sortings: [{ column: "CumePersons", order: 1 }]
          })
        );
        const groups = peopleFlows.value.reduce((group, item) => {
          group[item.HotspotId] = group[item.HotspotId] || [];
          group[item.HotspotId].push(item);
          return group;
        }, {} as { [key: string]: PeopleFlow[] })
        pieChart.data.labels = [];
        pieChart.data.datasets[0].data = [];
        barChartOptions.selectorOptions = [];
        for (const key of Object.keys(groups)) {
          const d = groups[key][0];
          barChartOptions.selectorOptions.push({ Id: d.HotspotId, Name: d.Hotspot.Name })
          pieChart.data.labels.push(d.Hotspot.Name);
          let sum = 0;
          for (const flow of groups[key]) {
            sum += flow.CumePersons;
          }
          pieChart.data.datasets[0].data.push(sum);
        }

        const flow = peopleFlows.value[0];
        console.log(
          "🚀 ~ file: Main.vue ~ line 492 ~ getStatistics ~ value",
          peopleFlows.value
        );
        changeHotspot(flow?.HotspotId);
      } catch {
        peopleFlows.value = [];
      }
    };

    const grid = ref<any>({});
    // const printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      title: "人流歷史紀錄查詢",
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      // printConfig: {
      //   sheetName: "人流歷史紀錄",
      //   columns: printColumns,
      //   modes: ["current", "selected", "all"]
      // },
      exportConfig: {
        filename: "人流歷史紀錄",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // 匯出欄位須與grid對應且須明確定義，否則會無法匯出
        columns: [{ field: "Date" }, { field: "Ivar.Name" }, { field: "ForwardCount" }, { field: "BackwardCount" }, { field: "HolidayString" }]
        // columns: [{ field: "Date" }, { field: "Hotspot.Name" }, { field: "CumePersons" }, { field: "MaxHourPersons" }, { field: "HolidayString" }]o
      },
      columns: [
        {
          field: "Date",
          title: "日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          slots: { default: "column-date" }
        },
        {
          field: "Ivar.Name",
          title: "地點",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ForwardCount",
          title: "當日累計進入人次",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "BackwardCount",
          title: "當日累計離開人次",
          showHeaderOverflow: true,
          showOverflow: true
        },
        // {
        //   field: "CumePersons",
        //   title: "當日人流數量(人)",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // },
        // {
        //   field: "MaxHourPersons",
        //   title: "當日最高人流數量(人)",
        //   showHeaderOverflow: true,
        //   showOverflow: true
        // },
        {
          field: "HolidayString",
          title: "假日",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter({ cellValue }) { return cellValue ? "1" : "" },
          visible: false
        },
      ],
      promises: {
        query: model
          ? params =>
            new Promise(resolve => {
              if (!gridQueryParams.hotspotId) { return; }

              params.condition = new Condition(
                "HotspotId",
                Operator.Equal,
                gridQueryParams.hotspotId
              ).and(params.condition!);

              if (gridQueryParams.startDate)
                params.condition = new Condition(
                  "Date",
                  Operator.GreaterThanOrEqual,
                  gridQueryParams.startDate
                ).and(params.condition!);
              if (gridQueryParams.endDate)
                params.condition = new Condition(
                  "Date",
                  Operator.LessThan,
                  gridQueryParams.endDate
                ).and(params.condition!);
              params.sortings = [new Sorting("Date", SortOrder.Descending)];
              model.dispatch("peopleFlow/query", params).then(async flows => {
                // const hotspots = await model.dispatch("hotspot/query", {
                //   condition: new Condition(
                //     "Id",
                //     Operator.In,
                //     flows.data.map((e: any) => e.HotspotId)
                //   )
                // });
                // flows.data.forEach((e: any) => {
                //   const maxHour = e.MaxHour?.replace(/Hour/i, "");
                //   // e.HotspotName = hotspots.find(
                //   //   (h: any) => h.Id === e.HotspotId
                //   // )?.Name;
                //   e.MaxHourPersons = e["Hour" + maxHour]
                // });
                getStatistics();
                resolve(flows);
              });
            })
          : undefined,
        queryAll: model
          ? params =>
            new Promise(resolve => {
              isExporting.value = true;
              params = params || { condition: new Condition() };
              if (gridQueryParams.hotspotId) {
                params.condition = new Condition(
                  "HotspotId",
                  Operator.Equal,
                  gridQueryParams.hotspotId
                ).and(params.condition!);
              }
              if (gridQueryParams.startDate)
                params.condition = new Condition(
                  "Date",
                  Operator.GreaterThanOrEqual,
                  gridQueryParams.startDate
                ).and(params.condition!);
              if (gridQueryParams.endDate)
                params.condition = new Condition(
                  "Date",
                  Operator.LessThan,
                  gridQueryParams.endDate
                ).and(params.condition!);
              params.sortings = [new Sorting("Date", SortOrder.Descending)];
              model.dispatch("peopleFlow/query", params).then(async flows => {
                // const hotspots = await model.dispatch("hotspot/query", {
                //   condition: new Condition(
                //     "Id",
                //     Operator.In,
                //     flows.data.map((e: any) => e.HotspotId)
                //   )
                // });
                // flows.forEach((e: any) => {
                //   const maxHour = e.MaxHour?.replace(/Hour/i, "");
                //   const year = Number(e.Date.substring(0, 4)) - 1911;
                //   e.Date = year + $h.formatDate(e.Date, "/MM/DD");
                //   // e.HotspotName = hotspots.find(
                //   //   (h: any) => h.Id === e.HotspotId
                //   // )?.Name;

                //   e.MaxHourPersons = e["Hour" + maxHour];
                // });
                isExporting.value = false;
                resolve(flows);
              });
            })
          : undefined
      }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        {
          field: "Date",
          title: "時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", readonly: true }
          }
        },
        {
          field: "HotspotId",
          title: "地點",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        {
          field: "IvarId",
          title: "人流計數主機",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },

        {
          field: "CumePersons",
          title: "當日人流數量(人)",
          span: 12,
          itemRender: { name: "$input", props: { readonly: true } }
        },
        { span: 24 }
      ]
    };


    // watch(gridQueryParams, _ => {
    //   getStatistics();
    //   grid.value.refresh();
    // });

    onMounted(async () => {
      hotspots.value = await model?.dispatch("hotspot/query");
      gridQueryParams.hotspotId = hotspots.value[0]?.Id;
      grid.value.refresh();
    });

    return {
      formatDate,
      hotspots,
      gridQueryParams,
      isExporting,
      grid,
      gridOptions,
      formOptions,
      pieChart,
      barChart,
      previewLineChartData,
      peopleFlows,
      barChartOptions,
      changeHotspot
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const flow = (
        await this.$model.dispatch("peopleFlow/query", {
          condition: new Condition("Date", Operator.Equal, row.Date).and(
            "HotspotId",
            Operator.Equal,
            row.HotspotId
          ),
          page: 1,
          pageSize: 1
        })
      ).data[0];
      if (flow)
        this.previewLineChartData.data.datasets[0].data = [
          flow.Hour00,
          flow.Hour01,
          flow.Hour02,
          flow.Hour03,
          flow.Hour04,
          flow.Hour05,
          flow.Hour06,
          flow.Hour07,
          flow.Hour08,
          flow.Hour09,
          flow.Hour10,
          flow.Hour11,
          flow.Hour12,
          flow.Hour13,
          flow.Hour14,
          flow.Hour15,
          flow.Hour16,
          flow.Hour17,
          flow.Hour18,
          flow.Hour19,
          flow.Hour20,
          flow.Hour21,
          flow.Hour22,
          flow.Hour23
        ];
      callback();
    }
  }
});
</script>
